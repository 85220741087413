import * as React from 'react';
import { useContext, useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import useHttp from "../../hooks/use-http";
import AuthContext from "../../store/auth-context";
import UserContext from "../../store/user-context";
import AlertPopupContext from "../../store/alert-popup-context";
import config from "../../config.json";

import classes from '../login/LoginForm.module.css';
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isMoment } from 'moment';
import ImageCropper from '../imageCropper/ImageCropper';

const theme = createTheme();

const RequestForm = (props) => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext);
    const role = userCtx.role;
    const token = authCtx.token;
    const { sendRequest: saveRequestedItem, sendRequest: fetchRequestedItem } = useHttp();
    const { isLoading, error, setError } = useHttp();
    const imageRef = useRef();
    const nameRef = useRef();
    const urlRef = useRef();
    const mintPriceRef = useRef();
    const dropCountRef = useRef();
    const discordLinkRef = useRef();
    const twitterLinkRef = useRef();
    const creatorAddressRef = useRef();
    const descriptionRef = useRef();
    const [nameError, setNameError] = useState(null);
    const [dropCountError, setDropCountError] = useState(null);
    const [mintPriceError, setMintPriceError] = useState(null);
    const [urlError, setUrlError] = useState(null);
    const [imageError, setImageError] = useState(null);
    const [discordLinkError, setDiscordLinkError] = useState(null);
    const [twitterLinkError, setTwitterLinkError] = useState(null);
    const [creatorAddressError, setCreatorAddressError] = useState(null);
    const [descriptionError, setDescriptionError] = useState(null);
    const [blockChainError, setBlockChainError] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const alertPopupCtx = useContext(AlertPopupContext);
    const setShowAlertPopup = alertPopupCtx.setShow;
    const setMessageData = alertPopupCtx.setMessageData;
    const [dateTimeValue, setDateTimeValue] = useState(null);
    const [scheduleId, setScheduleId] = useState(null);
    const params = useParams();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(null);
    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 25,
        y: 25
    });

    useEffect(() => {
        if (role === "admin") {
            if (typeof params.scheduleId !== "undefined") {
                setScheduleId(params.scheduleId);
            }
        }
    }, [params, role]);

    useEffect(() => {
        if (scheduleId !== null && role === "admin") {
            const handleScheduleItemResponse = (item) => {
                nameRef.current.value = item.name;
                urlRef.current.value = item.url;
                mintPriceRef.current.value = item.mint_price;
                dropCountRef.current.value = item.drop_count;
                discordLinkRef.current.value = item.discord_link;
                twitterLinkRef.current.value = item.url;
                creatorAddressRef.current.value = item.creator_address;
                descriptionRef.current.value = item.description;
                imageRef.current.value = item.image;
                setDateTimeValue(moment.unix(item.timestamp).format('YYYY-MM-DD h:mm a'));
            };

            fetchRequestedItem(
                {
                    url: `${config.URL}api/admin/request-schedule/${scheduleId}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                },
                handleScheduleItemResponse
            );
        }
    }, [params, scheduleId, fetchRequestedItem, role, token]);

    const handleChange = (newValue) => {
        setDateTimeValue(newValue);
    };

    const createScheduleResponseHandler = async (response) => {
        if (response.status === 422) {
            for (let message in response.validation_messages) {
                for (let error in response.validation_messages[message]) {
                    switch (message) {
                        case "name":
                            setNameError(response.validation_messages[message][error])
                            break;

                        case "start_time":
                            setStartTimeError(response.validation_messages[message][error])
                            break;

                        case "url":
                            setUrlError(response.validation_messages[message][error])
                            break;

                        case "image":
                            setImageError(response.validation_messages[message][error])
                            break;

                        case "blockchain":
                            setBlockChainError(response.validation_messages[message][error])
                            break;

                        case "description":
                            setDescriptionError(response.validation_messages[message][error])
                            break;

                        case "mint_price":
                            setMintPriceError(response.validation_messages[message][error])
                            break;


                        case "drop_count":
                            setDropCountError(response.validation_messages[message][error])
                            break;


                        case "discord_link":
                            setDiscordLinkError(response.validation_messages[message][error])
                            break;


                        case "twitter_link":
                            setTwitterLinkError(response.validation_messages[message][error])
                            break;


                        case "creator_address":
                            setCreatorAddressError(response.validation_messages[message][error])
                            break;

                        default:
                        //do nothing
                    }
                }
            };

            return false;
        }

        if (response.status === 500) {
            setError(response.detail);
            return false;
        }


        return true;
    }

    function isValidHttpUrl(string) {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
      }

    const handleSubmit = (event) => {
        event.preventDefault();

        setNameError(null);
        setUrlError(null);
        setMintPriceError(null);
        setDropCountError(null);
        setDiscordLinkError(null);
        setTwitterLinkError(null);
        setCreatorAddressError(null);
        setDescriptionError(null);
        setBlockChainError(null);
        setImageError(null);
        setStartTimeError(null);
        const data = new FormData(event.currentTarget);
        const name = nameRef.current.value;// data.get('name');
        const url = urlRef.current.value;// data.get('url');
        const drop_count = dropCountRef.current.value;// data.get('drop_count');
        const mint_price = mintPriceRef.current.value;// data.get('mint_price');
        const discord_link = discordLinkRef.current.value;// data.get('discord_link');
        const twitter_link = twitterLinkRef.current.value;// data.get('twitter_link');
        const creator_address = creatorAddressRef.current.value;// data.get('creator_address');
        const description = descriptionRef.current.value;// data.get('description');
        const image = imageRef.current.value;
        const blockchain = data.get('blockchain');
        const start_time = dateTimeValue === null ? null : isMoment(dateTimeValue) ? dateTimeValue.toDate().toString() : dateTimeValue;
        let isFormValid = true;

        if (name === null || name.trim().length < 3) {
            setNameError('Invalid name');
            isFormValid = false;
        }

        if (url === null || !isValidHttpUrl(url)) {
            setUrlError('Invalid url (https://www.algodrops.xyx)');
            isFormValid = false;
        }

        if (drop_count === null || drop_count.trim().length < 1) {
            setDropCountError('Invalid drop count');
            isFormValid = false;
        }

        if (mint_price === null || mint_price.trim().length < 1) {
            setMintPriceError('Invalid mint price');
            isFormValid = false;
        }

        if (description === null || description.trim().length < 3) {
            setDescriptionError('Invalid description');
            isFormValid = false;
        }

        if (blockchain === null || blockchain.trim().length < 3) {
            setBlockChainError('Invalid blockchain');
            isFormValid = false;
        }

        if (discord_link !== null && !isValidHttpUrl(discord_link)) {
            setDiscordLinkError('Invalid discord link (https://www.algodrops.xyx)');
            isFormValid = false;
        }

        if (twitter_link !== null && !isValidHttpUrl(twitter_link)) {
            setTwitterLinkError('Invalid twitter link (https://www.algodrops.xyx)');
            isFormValid = false;
        }

        if (creator_address === null || creator_address.trim().length !== 58) {
            setCreatorAddressError('Invalid creator address. Must be 58 characters');
            isFormValid = false;
        }

        if (image === null || !isValidHttpUrl(image)) {
            setImageError('Invalid image url (https://www.algodrops.xyx)');
            isFormValid = false;
        }

        if (start_time === null || start_time.trim().length < 3) {
            setStartTimeError('Invalid start time');
            isFormValid = false;
        }

        if (!isFormValid) {
            setShowAlertPopup(true);
            setMessageData({
                severity: "error",
                title: "Error",
                message: "Please complete all required fields",
            });
            return;
        }

        const itemDate = moment(start_time).unix();
        if (scheduleId !== null && role === "admin") {
            data.append("width", completedCrop.width * scale.scaleX);
            data.append("height", completedCrop.height * scale.scaleY);
            data.append("x", completedCrop.x * scale.scaleX);
            data.append("y", completedCrop.y * scale.scaleY);
            data.append("schedule_id", scheduleId);
            data.append("file", image);
            data.append("mint_price", mint_price);
            data.append("drop_count", drop_count);
            data.append("timestamp", itemDate);
            data.append("is_active", true);

            axios.post(config.URL + "api/admin/request-schedule", data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                }
            }).then(async (response) => {
                if (!await createScheduleResponseHandler(response)) {
                    setMessageData({
                        severity: "error",
                        title: "Error",
                        message: error === null ? "Failed to save new schedule item" : error,
                    });
                    return;
                }

                setShowAlertPopup(true);
                setMessageData({
                    severity: "success",
                    title: "Success",
                    message: "Successfully approved requested schedule item",
                });
                props.changeTab(null, "request-list");
                navigate('/admin-actions/request-list');
            });
        } else {
            saveRequestedItem(
                {
                    url: config.URL + "api/request-drop",
                    method: "POST",
                    body: {
                        name,
                        blockchain,
                        url,
                        image,
                        description,
                        mint_price,
                        drop_count,
                        discord_link,
                        twitter_link,
                        creator_address,
                        timestamp: itemDate
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                },
                createScheduleResponseHandler
            );

            setShowAlertPopup(true);
            setMessageData({
                severity: "success",
                title: "Success",
                message: "Successfully saved requested item",
            });
            navigate('/');
        }
    };
    
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            {scheduleId !== null && role === "admin" ? "Approve Drop" : "Request Drop Form"}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Drop Name"
                                name="name"
                                InputLabelProps={{ shrink: true }}
                                inputRef={nameRef}
                                autoComplete="name"
                                autoFocus
                                error={nameError !== null && true}
                                helperText={nameError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="url"
                                label="Drop URL"
                                name="url"
                                InputLabelProps={{ shrink: true }}
                                inputRef={urlRef}
                                autoComplete="url"
                                error={urlError !== null && true}
                                helperText={urlError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="image"
                                label="Image Url"
                                name="image"
                                InputLabelProps={{ shrink: true }}
                                inputRef={imageRef}
                                autoComplete="image"
                                error={imageError !== null && true}
                                helperText={imageError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mint_price"
                                label="Mint Price"
                                name="mint_price"
                                InputLabelProps={{ shrink: true }}
                                inputRef={mintPriceRef}
                                autoComplete="mint_price"
                                error={mintPriceError !== null && true}
                                helperText={mintPriceError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="drop_count"
                                label="Drop Count"
                                name="drop_count"
                                InputLabelProps={{ shrink: true }}
                                inputRef={dropCountRef}
                                autoComplete="drop_count"
                                error={dropCountError !== null && true}
                                helperText={dropCountError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="discord_link"
                                label="Discord Link"
                                name="discord_link"
                                InputLabelProps={{ shrink: true }}
                                inputRef={discordLinkRef}
                                autoComplete="discord_link"
                                error={discordLinkError !== null && true}
                                helperText={discordLinkError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="twitter_link"
                                label="Twitter Link"
                                name="twitter_link"
                                InputLabelProps={{ shrink: true }}
                                inputRef={twitterLinkRef}
                                autoComplete="twitter_link"
                                error={twitterLinkError !== null && true}
                                helperText={twitterLinkError}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="creator_address"
                                label="Creator Address"
                                name="creator_address"
                                InputLabelProps={{ shrink: true }}
                                inputRef={creatorAddressRef}
                                autoComplete="creator_address"
                                error={creatorAddressError !== null && true}
                                helperText={creatorAddressError}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="description"
                                InputLabelProps={{ shrink: true }}
                                inputRef={descriptionRef}
                                label="Description"
                                id="description"
                                multiline={true}
                                autoComplete="description"
                                error={descriptionError !== null && true}
                                helperText={descriptionError}
                            />
                            <div style={{ padding: "15px 0px" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Blockchain</InputLabel>
                                    <Select
                                        labelId="blockchain"
                                        id="blockchain"
                                        name="blockchain"
                                        value={'algo'}
                                        label="Blockchain"
                                        error={blockChainError !== null && true}
                                    >
                                        <MenuItem value="algo">ALGO</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div style={{ padding: "10px 0px 20px 0px" }}>
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        id="start_time"
                                        label="Start Time"
                                        name="start_time"
                                        InputLabelProps={{ shrink: true }}
                                        value={dateTimeValue}
                                        error={startTimeError !== null && true}
                                        helperText={startTimeError}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                                {startTimeError !== null && (<InputLabel style={{ margin: "3px 14px 0px 14px ", "fontSize": "0.75rem", color: "#d32f2f" }} id="demo-simple-select-start_time">{startTimeError}</InputLabel>)}
                                <p style={{ fontSize: "0.75rem"}}>Use your local timezone</p>
                            </div>

                            {scheduleId !== null && role === "admin" && (
                                <FormControl fullWidth>
                                    <ImageCropper src={imageRef.current.value} completedCrop={completedCrop} setCompletedCrop={setCompletedCrop} crop={crop} setCrop={setCrop} setScale={setScale} />
                                </FormControl>
                            )}
                            <Divider light />
                            <Button
                                disabled={isLoading}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {scheduleId !== null && role === "admin" ? "Approve" : "Submit"}
                            </Button>
                            <div style={{ color: "red" }}>
                                {error === "500 error" && <p>Failed to update schedule item. Reupload image if recropping.</p>}
                                {error === "Grant failed!" && <p className={classes.invalid}>Invalid Credentials</p>}
                                {error === "Grant invalid!" && <p className={classes.invalid}>Check email and click activation link before logging in</p>}
                            </div>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

export default RequestForm;