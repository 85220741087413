import { Button, FilledInput, FormControl, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const blacklistSearchPaths = [
    "/login",
    "/signup",
    "/request"
]
const Search = () => {
    const location = useLocation();
    const path = location.pathname
    const [searchValue, setSearchValue] = useState()
    const [showSearch, setShowSearch] = useState(true)
    const nagivate = useNavigate();
    const handleSearch = async function (e) {
        e.preventDefault();
        setSearchValue('')
        if (searchValue.length === 9) {            
            nagivate(`/asset/${searchValue}`);
        } else {
            nagivate(`/collection/${searchValue}`);
        }
    }

    useEffect(() => {
        if (blacklistSearchPaths.includes(path)) {
            setShowSearch(false);
        } else {
            setShowSearch(true)
        }
    }, [path])
    return (
        <>
            {showSearch && <div style={{ textAlign: "center", paddingLeft: "20%", paddingRight: "20%" }}>
                <form onSubmit={handleSearch}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <FilledInput
                            value={searchValue}
                            name="searchVal"
                            onChange={(e) => { setSearchValue(e.target.value) }}
                            style={{ background: "white" }}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                            endAdornment={<InputAdornment position="start"><Button variant="contained" onClick={handleSearch}>GO</Button></InputAdornment>}
                        />
                    </FormControl>
                </form>
            </div>}
        </>
    )
}

export default Search;