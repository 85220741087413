import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

import useHttp from "../../../hooks/use-http";
import AuthContext from "../../../store/auth-context";
import config from "../../../config.json";

const RequestedItems = (props) => {
    const navigate = useNavigate();
    const { sendRequest: fetchRequestedItems } = useHttp();
    const [requestedItems, setRequestedItems] = useState([]);
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;

    useEffect(() => {
        const handleRequestedItemsResponse = (items) => {
            setRequestedItems(items._embedded.schedule);
        };

        fetchRequestedItems(
            {
                url: config.URL + "api/admin/request-schedule",
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            },
            handleRequestedItemsResponse
        );
    }, [fetchRequestedItems, setRequestedItems, token]);

    const viewRequestHandler = (scheduleId) => {
        props.changeTab(null, "request");
        navigate(`/admin-actions/request/${scheduleId}`);
    };

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Requested Drops
                        </Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>Start Time</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {typeof requestedItems !== "undefined" && requestedItems.length > 0 && (
                                    requestedItems.map((item) => (
                                        <TableRow key={item.schedule_id}>
                                            <TableCell style={{ textAlign: "center" }}>{item.name}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{moment.unix(item.timestamp).format('YYYY-MM-DD h:mm a')}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                <Button size="small" onClick={viewRequestHandler.bind(null, item.schedule_id)} variant="outlined">
                                                    View
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                        {requestedItems.length === 0 && <span style={{ textAlign: "center" }}>No Alerts Found!</span>}
                    </Paper>
                </Grid>
            </Container>
        </>
    )
}

export default RequestedItems;