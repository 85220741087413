import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';

import Calendar from 'react-calendar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Badge from '@mui/material/Badge';

import UserContext from "../../store/user-context";
import useHttp from "../../hooks/use-http";
import CalendarItems from './CalendarItems';
import config from "../../config.json";

import './NftCalendar.css';


const NftCalendar = () => {
    const userCtx = useContext(UserContext);
    const [value, onChange] = useState(new Date());
    const [nftDrops, setNftDrops] = useState([]);
    const [dateItems, setDateItems] = useState([]);
    const [shownDate, setShownDate] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const alerts = userCtx.alerts;
    const [startDate, setStartDate] = useState(null);

    const { sendRequest: fetchDrops } = useHttp();

    useEffect(() => {
        const handleDropResponse = (drops) => {
            setNftDrops(drops._embedded.schedule);
        };

        let searchParams = "?drop=true&is_active=" + true;
        if (startDate !== null) {
            searchParams += `&start_date=${startDate}`;
        }
        fetchDrops(
            {
                url: `${config.URL}api/drops${searchParams}`
            },
            handleDropResponse
        );
    }, [fetchDrops, startDate]);

    const dateClickHandler = useCallback(async (value, event) => {
        const clickedDate = new Date(value);
        const dateItems = nftDrops.filter((item) => {
            const itemDate = new moment.unix(item.timestamp).format('ddd MMM DD YYYY');
            if (clickedDate.toDateString() === itemDate) {
                //check if alert saved yet or not and update item with flag
                for (const key in alerts) {
                    if (alerts[key].scheduleId === item.schedule_id) {
                        item.foundAlert = alerts[key];
                    }
                }
                return item;
            }

            return false;
        });

        setDateItems(dateItems);
        setShownDate(clickedDate.toLocaleDateString());
    }, [nftDrops, alerts]);

    //init todays drops in calendar
    useEffect(() => {
        //needed for reload of drops on date reange query
        if (isFirstLoad && nftDrops.length > 0) {
            dateClickHandler(new Date().toDateString());
            setIsFirstLoad(false);
        }
    }, [nftDrops, isFirstLoad, dateClickHandler, alerts]);

    useEffect(() => {
        if (startDate !== null && nftDrops.length > 0) {
            dateClickHandler(startDate);
        }
    }, [nftDrops, dateClickHandler, alerts, startDate]);

    const displayDropCount = (date, view) => {
        const calendarDate = new Date(date.date);
        let count = 0;
        for (const item of nftDrops) {
            const itemDate = new moment.unix(item.timestamp).format('ddd MMM DD YYYY');
            if (calendarDate.toDateString() === itemDate) {
                count++;
            }
        }

        return (
            <>
                <br />
                <Badge badgeContent={count} color="error" />
            </>
        );
    }

    const monthChangeHandler = (data) => {
        const monthStartDate = new Date(data.activeStartDate);
        setStartDate(monthStartDate.toDateString());
        onChange(monthStartDate);
    }

    return <div>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Calendar
                        onChange={onChange}
                        onClickDay={dateClickHandler}
                        tileContent={displayDropCount}
                        value={value}
                        minDetail="month"
                        onActiveStartDateChange={monthChangeHandler}
                        next2Label={null}
                        prev2Label={null}
                        nextLabel={<NavigateNextIcon />}
                        prevLabel={<NavigateBeforeIcon />}
                    />
                </Paper>
            </Grid>
        </Container>
        <CalendarItems items={dateItems} dateShowing={shownDate} />
    </div>
}

export default NftCalendar;