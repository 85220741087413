import { Grid, Link } from '@mui/material';
import SportsBarIcon from '@mui/icons-material/SportsBar';

import classes from './FooterStyle.module.css';
import { NavLink } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <div className={classes.footer}>
            <Grid container >
                <Grid item xs={4} md={2} >
                    <span style={{ fontSize: 15 }}>Important Links:</span>
                </Grid>
                <Grid item xs={4} md={7} >
                    <Link style={{ color: "white", fontSize: 15 }} href="https://wallet.myalgo.com/">
                        My Algo Wallet
                    </Link>
                    <br />
                    <Link style={{ color: "white", fontSize: 15 }} href="https://perawallet.app/">
                        Pera Wallet
                    </Link>
                </Grid>
                <Grid item xs={4} md={3} >
                    <Link style={{ color: "white", fontSize: 15 }} href="https://twitter.com/algodrops_xyz">
                    <span style={{ fontSize: 15 }}>Algodrop_xyz   </span><TwitterIcon style={{height:".5em"}}/>
                    </Link>
                    <br />
                    <NavLink style={{ color: "white", textDecoration: "none" }} to="buy-me-a-beer" >
                        <span style={{ fontSize: 15 }}>Buy Me A Beer </span><SportsBarIcon style={{height:".5em"}} fontSize="small" />
                    </NavLink>

                </Grid>
            </Grid>
        </div>
    );
}

export default Footer;