import React, { useState, useEffect } from "react";

const UserContext = React.createContext({
    alerts: [],
    permissions: [],
    userName: null,
    userEmail: false,
    role: null,
    allowEmails: true,
    minPrice: 5,
    hashTags: null,
    creatorAddressA: '',
    creatorAddressB: '',
    creatorAddressC: '',
    creatorAddressD: '',
    creatorAddressE: '',
    minPriceDiscord: 5,
    creatorAddressADiscord: '',
    creatorAddressBDiscord: '',
    creatorAddressCDiscord: '',
    creatorAddressDDiscord: '',
    creatorAddressEDiscord: '',
    discordNftSalesChannelId: '',
    setAlerts: (alerts) => { },
    setPermissions: (permissions) => { },
    setUserName: (name) => { },
    setEmail: (email) => { },
    setAllowEmails: (allow) => { },
    setMinPrice: (minPrice) => { },
    setMinPriceDiscord: (minPrice) => { },
    setRole: (role) => { },
    setHashTags: (hashTags) => { },
    setCreatorAddressA: (address) => { },
    setCreatorAddressB: (address) => { },
    setCreatorAddressC: (address) => { },
    setCreatorAddressD: (address) => { },
    setCreatorAddressE: (address) => { },
    setCreatorAddressADiscord: (address) => { },
    setCreatorAddressBDiscord: (address) => { },
    setCreatorAddressCDiscord: (address) => { },
    setCreatorAddressDDiscord: (address) => { },
    setCreatorAddressEDiscord: (address) => { },
    setDiscordNftSalesChannelId: (channelId) => { }
});

export const UserContextProvider = (props) => {
    const [alerts, setAlerts] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [allowEmails, setAllowEmails] = useState(true);
    const [minPrice, setMinPrice] = useState(5);
    const [minPriceDiscord, setMinPriceDiscord] = useState(5);
    const [role, setRole] = useState(null);
    const [hashTags, setHashTags] = useState(null);
    const [creatorAddressA, setCreatorAddressA] = useState('');
    const [creatorAddressB, setCreatorAddressB] = useState('');
    const [creatorAddressC, setCreatorAddressC] = useState('');
    const [creatorAddressD, setCreatorAddressD] = useState('');
    const [creatorAddressE, setCreatorAddressE] = useState('');
    const [creatorAddressADiscord, setCreatorAddressADiscord] = useState('');
    const [creatorAddressBDiscord, setCreatorAddressBDiscord] = useState('');
    const [creatorAddressCDiscord, setCreatorAddressCDiscord] = useState('');
    const [creatorAddressDDiscord, setCreatorAddressDDiscord] = useState('');
    const [creatorAddressEDiscord, setCreatorAddressEDiscord] = useState('');
    const [discordNftSalesChannelId, setDiscordNftSalesChannelId] = useState('');

    useEffect(() => {
        setAlerts(alerts);
    }, [alerts])

    useEffect(() => {
        setPermissions(permissions);
    }, [permissions])

    useEffect(() => {
        setUserEmail(userEmail);
    }, [userEmail])

    useEffect(() => {
        setUserName(userName);
    }, [userName])

    useEffect(() => {
        setAllowEmails(allowEmails);
    }, [allowEmails])

    useEffect(() => {
        setMinPrice(minPrice);
    }, [minPrice])

    useEffect(() => {
        setMinPriceDiscord(minPriceDiscord);
    }, [minPriceDiscord])

    useEffect(() => {
        setRole(role);
    }, [role])
    
    useEffect(() => {
        setHashTags(hashTags);
    }, [hashTags])

    useEffect(() => {
        setCreatorAddressA(creatorAddressA);
    }, [creatorAddressA])

    useEffect(() => {
        setCreatorAddressB(creatorAddressB);
    }, [creatorAddressB])

    useEffect(() => {
        setCreatorAddressC(creatorAddressC);
    }, [creatorAddressC])

    useEffect(() => {
        setCreatorAddressD(creatorAddressD);
    }, [creatorAddressD])

    useEffect(() => {
        setCreatorAddressE(creatorAddressE);
    }, [creatorAddressE])

    useEffect(() => {
        setCreatorAddressADiscord(creatorAddressADiscord);
    }, [creatorAddressADiscord])

    useEffect(() => {
        setCreatorAddressBDiscord(creatorAddressBDiscord);
    }, [creatorAddressBDiscord])

    useEffect(() => {
        setCreatorAddressCDiscord(creatorAddressCDiscord);
    }, [creatorAddressCDiscord])

    useEffect(() => {
        setCreatorAddressDDiscord(creatorAddressDDiscord);
    }, [creatorAddressDDiscord])

    useEffect(() => {
        setCreatorAddressEDiscord(creatorAddressEDiscord);
    }, [creatorAddressEDiscord])

    useEffect(() => {
        setDiscordNftSalesChannelId(discordNftSalesChannelId);
    }, [discordNftSalesChannelId])

    const contextValue = {
        alerts: alerts,
        permissions: permissions,
        userName: userName,
        userEmail: userEmail,
        allowEmails: allowEmails,
        minPrice: minPrice,
        minPriceDiscord: minPriceDiscord,
        role: role,
        hashTags: hashTags,
        creatorAddressA: creatorAddressA,
        creatorAddressB: creatorAddressB,
        creatorAddressC: creatorAddressC,
        creatorAddressD: creatorAddressD,
        creatorAddressE: creatorAddressE,
        discordNftSalesChannelId: discordNftSalesChannelId,
        creatorAddressADiscord: creatorAddressADiscord,
        creatorAddressBDiscord: creatorAddressBDiscord,
        creatorAddressCDiscord: creatorAddressCDiscord,
        creatorAddressDDiscord: creatorAddressDDiscord,
        creatorAddressEDiscord: creatorAddressEDiscord,
        setAlerts: setAlerts,
        setPermissions: setPermissions,
        setUserName: setUserName,
        setUserEmail: setUserEmail,
        setAllowEmails: setAllowEmails,
        setMinPrice: setMinPrice,
        setMinPriceDiscord: setMinPriceDiscord,
        setRole: setRole,
        setHashTags: setHashTags,
        setCreatorAddressA: setCreatorAddressA,
        setCreatorAddressB: setCreatorAddressB,
        setCreatorAddressC: setCreatorAddressC,
        setCreatorAddressD: setCreatorAddressD,
        setCreatorAddressE: setCreatorAddressE,
        setCreatorAddressADiscord: setCreatorAddressADiscord,
        setCreatorAddressBDiscord: setCreatorAddressBDiscord,
        setCreatorAddressCDiscord: setCreatorAddressCDiscord,
        setCreatorAddressDDiscord: setCreatorAddressDDiscord,
        setCreatorAddressEDiscord: setCreatorAddressEDiscord,
        setDiscordNftSalesChannelId: setDiscordNftSalesChannelId
    }

    return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>
}

export default UserContext;