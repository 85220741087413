import React, { useState } from "react";

const AlertPopupContext = React.createContext({
    show: false,
    severity: null,
    title: null,
    message: null,
    messageData: {
        severity: null,
        title: null,
        message: null,
    },
    setShow: (token) => { },
    setMessageData: (severity) => { },
    setSeverity: (severity) => { },
    setTitle: (title) => { },
    setMessage: (message) => { },
});

export const AlertPopupContextProvider = (props) => {
    const [show, setShow] = useState(false);
    const [messageData, setMessageData] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [title, setTitle] = useState(null);
    const [message, setMessage] = useState(null);

    const contextValue = {
        show,
        severity,
        message,
        title,
        messageData,
        setMessageData,
        setShow,
        setSeverity,
        setTitle,
        setMessage,
    }

    return <AlertPopupContext.Provider value={contextValue}>{props.children}</AlertPopupContext.Provider>
}

export default AlertPopupContext;