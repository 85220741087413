import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';

import AlertPopupContext from "../../store/alert-popup-context";

const AlertPopup = (props) => {
    const [alert, setAlert] = useState(true);
    const alertPopupCtx = useContext(AlertPopupContext);
    const setShowAlertPopup = alertPopupCtx.setShow;
    const messageData = alertPopupCtx.messageData;
    const setMessageData = alertPopupCtx.setMessageData;


    useEffect(() => {
        setTimeout(() => {
            setAlert(false);
            setShowAlertPopup(false);
            setMessageData({
                severity: null,
                title: null,
                message: null,
            });
        }, 4000);
    }, [setMessageData, setShowAlertPopup]);

    return (
        <>
            {alert && (
                <Alert style={{
                    position: 'fixed',
                    bottom: '10px',
                    right: '10px',
                    margin: '0px',
                }} severity={messageData.severity}>
                    <AlertTitle>{messageData.title}</AlertTitle>
                    <p style={{ paddingRight: "40px" }}>{messageData.message}</p>
                </Alert>
            )}
        </>
    )
}

export default AlertPopup;