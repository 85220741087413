import React, { Suspense, useContext, useEffect, useCallback } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import './App.css';
import Layout from './components/layout/Layout';
import LoadingSpinner from "./components/UI/LoadingSpinner";

import useHttp from "./hooks/use-http";
import AuthContext from "./store/auth-context";
import UserContext from "./store/user-context";
import AlertPopupContext from "./store/alert-popup-context";
import AlertPopup from "./components/UI/AlertPopup";
import ViewCalendarItem from "./components/calendar/ViewCalendarItem";
import AdminRequest from "./components/admin/request/AdminRequest";
import config from "./config.json";
import NftCalendar from "./components/calendar/NftCalendar";
import ProductView from "./components/products/ProductView";

const HomePage = React.lazy(() => import("./pages/HomePage"));
const Login = React.lazy(() => import("./pages/Login"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const Alerts = React.lazy(() => import("./pages/Alerts"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Request = React.lazy(() => import("./pages/Request"));
const Settings = React.lazy(() => import("./pages/Settings"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Donate = React.lazy(() => import("./pages/Donate"));
const Products = React.lazy(() => import("./pages/Products"));
const RecentNftSales = React.lazy(() => import("./pages/RecentNftSales"));
const Billing = React.lazy(() => import("./pages/Billing"));
const Asset = React.lazy(() => import("./pages/Asset"));
const CreatorCollection = React.lazy(() => import("./pages/CreatorCollection"));

function App() {
  const { sendRequest: fetchAlerts } = useHttp();
  const { sendRequest: fetchUser } = useHttp();
  const { sendRequest: fetchUserSubscriptions } = useHttp();
  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);
  const alertPopupCtx = useContext(AlertPopupContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const token = authCtx.token;
  const setAlerts = userCtx.setAlerts;
  const setPermissions = userCtx.setPermissions;
  const permissions = userCtx.permissions;
  const setCreatorAddressA = userCtx.setCreatorAddressA;
  const setCreatorAddressB = userCtx.setCreatorAddressB;
  const setCreatorAddressC = userCtx.setCreatorAddressC;
  const setCreatorAddressD = userCtx.setCreatorAddressD;
  const setCreatorAddressE = userCtx.setCreatorAddressE;
  const setCreatorAddressADiscord = userCtx.setCreatorAddressADiscord;
  const setCreatorAddressBDiscord = userCtx.setCreatorAddressBDiscord;
  const setCreatorAddressCDiscord = userCtx.setCreatorAddressCDiscord;
  const setCreatorAddressDDiscord = userCtx.setCreatorAddressDDiscord;
  const setCreatorAddressEDiscord = userCtx.setCreatorAddressEDiscord;
  const setDiscordNftSalesChannelId = userCtx.setDiscordNftSalesChannelId;
  const setUserName = userCtx.setUserName;
  const setUserEmail = userCtx.setUserEmail;
  const setAllowEmails = userCtx.setAllowEmails;
  const setMinPrice = userCtx.setMinPrice;
  const setHashTags = userCtx.setHashTags;
  const setMinPriceDiscord = userCtx.setMinPriceDiscord;
  const setRole = userCtx.setRole;
  const role = userCtx.role;
  const showAlertPopup = alertPopupCtx.show;

  useEffect(() => {
    if (isLoggedIn && token !== '') {
      const handleAlertsResponse = (alertResponse) => {
        alertResponse._embedded.alerts.forEach(element => {
          element.startTime = element.start_time;
        });
        setAlerts(alertResponse._embedded.alerts);
      };

      fetchAlerts(
        {
          url: config.URL + "api/email-alerts",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        },
        handleAlertsResponse
      );
    }
  }, [isLoggedIn, token, fetchAlerts, setAlerts]);

  const handleUserResponse = useCallback((response) => {
    setUserEmail(response.email);
    setUserName(response.name);
    setAllowEmails(response.allow_extra_email);
    setCreatorAddressA(response.creator_address_a);
    setCreatorAddressB(response.creator_address_b);
    setCreatorAddressC(response.creator_address_c);
    setCreatorAddressD(response.creator_address_d);
    setCreatorAddressE(response.creator_address_e);
    setMinPrice(response.min_price);
    setHashTags(response.hash_tags);
    setCreatorAddressADiscord(response.creator_address_a_discord);
    setCreatorAddressBDiscord(response.creator_address_b_discord);
    setCreatorAddressCDiscord(response.creator_address_c_discord);
    setCreatorAddressDDiscord(response.creator_address_d_discord);
    setCreatorAddressEDiscord(response.creator_address_e_discord);
    setDiscordNftSalesChannelId(response.discord_nft_sales_channel_id);
    setMinPriceDiscord(response.min_price_discord);
    if (typeof response.roles !== "undefined" && response.roles.includes("superhero")) {
      setRole("admin");
    }
  }, [setUserEmail, setUserName, setRole, setAllowEmails, setCreatorAddressA, setCreatorAddressADiscord, setCreatorAddressB, setCreatorAddressBDiscord, setCreatorAddressC, setCreatorAddressCDiscord, setCreatorAddressD, setCreatorAddressDDiscord, setCreatorAddressE, setCreatorAddressEDiscord, setDiscordNftSalesChannelId, setHashTags, setMinPrice, setMinPriceDiscord]);

  useEffect(() => {
    if (isLoggedIn && token !== '') {
      fetchUser(
        {
          url: config.URL + "api/user/profile",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        },
        handleUserResponse
      );
    }
  }, [isLoggedIn, token, fetchUser, handleUserResponse]);

  useEffect(() => {
    const handleUserSubscriptionResponse = (response) => {
      // console.log(response);
      // console.log(response.plans);
      setPermissions(response.plans);
    }

    if (isLoggedIn && token !== '') {
      fetchUserSubscriptions(
        {
          url: config.URL + "api/user-billing/subscriptions",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        },
        handleUserSubscriptionResponse
      );
    }
  }, [isLoggedIn, token, fetchUserSubscriptions, setPermissions]);

  return (
    <>
      <Layout>
        <Suspense fallback={<div style={{ margin: "auto", textAlign: "center" }}><LoadingSpinner /></div>}>
          <Routes>
            <Route path="/" element={<Navigate replace to="/drops" />} />
            <Route path="/drops" element={<HomePage />} />
            <Route path="/calendar" element={<NftCalendar />} />
            <Route path="/drops/:dropId" element={<ViewCalendarItem />} />
            <Route path="/buy-me-a-beer" element={<Donate />} />
            <Route path="bots" element={<Products />} />
            <Route path="/bots" element={<Products />} />
            <Route path={`/bots/:billingPlanId`}  element={<ProductView />} />
            <Route path="recent-nft-sales" element={<RecentNftSales />} />
            <Route path="request" element={<Request />} />
            <Route path="asset/:assetId" element={<Asset />} />
            <Route path="collection/:address" element={<CreatorCollection />} />
            {!isLoggedIn && <Route path="signup" element={<SignUp />} />}
            {!isLoggedIn && <Route path="login" element={<Login />} />}
            {!isLoggedIn && <Route path="forgot-password" element={<ForgotPassword />} />}
            {isLoggedIn && <Route path="alerts" element={<Alerts />} />}
            {isLoggedIn && permissions.length > 0 && <Route path="billing" element={<Billing />} />}
            {isLoggedIn && <Route path="settings" element={<Settings />} />}            
            {isLoggedIn && <Route path="contact-us" element={<ContactUs />} />}
            {isLoggedIn && role === "admin" && (<Route path="/admin-actions" element={<Admin />}>
              <Route path={`/admin-actions/contact-us-list`} element={<Admin />} />
              <Route path={`/admin-actions/contact-us/:messageId`} element={<Admin />} />
              <Route path={`/admin-actions/request-list`} element={<Admin />} />
              <Route path={`/admin-actions/request`} element={<Admin />} />
              <Route path={`/admin-actions/request/:scheduleId`} element={<AdminRequest />} />
              <Route path={`/admin-actions/schedule`} element={<Admin />} />
              <Route path={`/admin-actions/schedule/:scheduleId`} element={<Admin />} />
              <Route path={`/admin-actions/schedule-list`} element={<Admin />} />
              <Route path={`/admin-actions/billing-plan`} element={<Admin />} />
              <Route path={`/admin-actions/billing-plan/:billingPlanId`} element={<Admin />} />
              <Route path={`/admin-actions/billing-plan-list`} element={<Admin />} />
            </Route>)}

            <Route path="*" element={<HomePage />} />
          </Routes>
        </Suspense>
      </Layout>
      {showAlertPopup && <AlertPopup />}
    </>
  );
}

export default App;
