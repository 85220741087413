import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import AuthContext from "../../../store/auth-context";
import RequestedItems from './RequestedItems';
import RequestForm from '../../request/RequestForm';

const AdminRequest = () => {
    const [tabValue, setTabValue] = React.useState("request-list");
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const navigate = useNavigate();
    const location = useLocation();
    let pathname = location.pathname;

    useEffect(() => {
        let path = pathname.replace('/admin-actions/', '');
        if (path.indexOf('/') >= 0) {
            path = path.substring(0, path.indexOf('/'));
        }
        setTabValue(path);
    }, [setTabValue, pathname]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        navigate('/admin-actions/' + newValue);
    };

    return (
        <>
            <Tabs variant="scrollable"
                value={tabValue}
                onChange={handleChange}
            >
                <Tab value="request-list" label="View Request" />
                <Tab disabled value="request" label="Approve Request" />
            </Tabs>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
                {isLoggedIn && tabValue === "request-list" && (<RequestedItems changeTab={handleChange} />)}
                {isLoggedIn && tabValue === "request" && (<RequestForm changeTab={handleChange} />)}
            </Box>
        </>
    );
}

export default AdminRequest;