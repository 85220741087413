import React, { useContext } from 'react';
import moment from 'moment';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

import config from "../../config.json";
import UserContext from "../../store/user-context";
import AuthContext from "../../store/auth-context";
import useHttp from "../../hooks/use-http";
import { NavLink } from 'react-router-dom';

const CalendarItems = (props) => {
    const { sendRequest: alertRequest } = useHttp();
    const userCtx = useContext(UserContext);
    const authCtx = useContext(AuthContext);
    const alerts = userCtx.alerts;
    const setAlerts = userCtx.setAlerts;
    const token = authCtx.token;
    const isLoggedIn = authCtx.isLoggedIn;

    const addAlertHandler = (item) => {
        const addAlertResponseHandler = (response) => {
            item.foundAlert = "found";
            const newAlerts = alerts.concat(response);
            setAlerts(newAlerts);
        }

        alertRequest(
            {
                url: config.URL + "api/email-alerts",
                method: "POST",
                body: {
                    schedule_id: item.schedule_id,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            },
            addAlertResponseHandler
        );
    }

    // @todo: duplicated code with AlertList.js
    const removeAlertHandler = (item) => {
        const removeAlertResponseHandler = (response) => {
            const updatedItems = alerts.filter(alert => alert.scheduleId !== item.schedule_id);
            setAlerts(updatedItems);
            delete item.foundAlert;
        }

        alertRequest(
            {
                url: config.URL + "api/email-alerts/" + item.schedule_id,
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            },
            removeAlertResponseHandler
        );
    }

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Drops for {props.dateShowing}
                        </Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>Start Time</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                {props.items.length > 0 && (
                                    props.items.map((item) => (
                                        <TableRow key={item.schedule_id}>
                                            <TableCell style={{ textAlign: "center" }}>{item.name}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>{moment.unix(item.timestamp).format('YYYY-MM-DD h:mm a')}</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                <Button size="small" variant="outlined">
                                                    <NavLink to={`/drops/${item.schedule_id}`}>View</NavLink>
                                                </Button>
                                                {isLoggedIn && typeof item.foundAlert === "undefined" && !moment(moment.unix(item.timestamp).format('YYYY-MM-DD h:mm a')).isBefore(moment(new Date())) && <Button size="small" style={{ margin: "5px" }} onClick={addAlertHandler.bind(null, item)} variant="outlined">Add Alert</Button>}
                                                {isLoggedIn && typeof item.foundAlert !== "undefined" && !moment(moment.unix(item.timestamp).format('YYYY-MM-DD h:mm a')).isBefore(moment(new Date())) && <Button size="small" style={{ margin: "0 10px 0 10px" }} onClick={removeAlertHandler.bind(null, item)} variant="outlined">Remove Alert</Button>}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                        {props.items.length === 0 && <span style={{ textAlign: "center" }}>No Drops Found!</span>}
                    </Paper>
                </Grid>
            </Container>
        </>
    );
}

export default CalendarItems;