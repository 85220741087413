import { Button, CircularProgress, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const AndPayEmbedCheckout = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [iframeDisplay, setIframeDisplay] = useState('none');
    const [message, setMessage] = useState('');
    const [link, setLink] = useState('');
    const permission = props.permission;

    const iframeLoadHandler = () => {
        setIsLoading(false);
        setIframeDisplay('');
    }

    useEffect(() => {
        switch (permission) {
            case "twitter_sales_bot":
                console.log('in it');
                setLink(<Button><NavLink to="/settings" >SETTINGS</NavLink></Button>);
                setMessage(`Please go to settings after paying to start setting up your new bot`);
                break;
            
                default:
            }
    }, [permission]);

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', textAlign: 'center' }}>
                {isLoading === true && (<CircularProgress color="secondary" />)}
                <Typography style={{ display: iframeDisplay }} variant="body2" gutterBottom>
                    <span>{message}</span>
                </Typography>
                <Typography style={{ display: iframeDisplay }} variant="body2" gutterBottom>
                    <span>{link}</span>
                </Typography>
                <iframe title="andPay" style={{ display: iframeDisplay }} src={props.payment_link} width="auto" height="750" onLoad={iframeLoadHandler}></iframe>
                <Typography style={{ display: iframeDisplay }} variant="body2" gutterBottom>
                    <span>{message}</span>
                </Typography>
                <Typography style={{ display: iframeDisplay }} variant="body2" gutterBottom>
                    <span>{link}</span>
                </Typography>
            </Container>
        </>
    );
}

export default AndPayEmbedCheckout;