import { FormControl } from "@mui/material";
import { useRef, useEffect } from "react";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './canvasPreview'


const useDebounceEffect = (fn, waitTime, deps) => {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, [deps])
        }, waitTime)

        return () => {
            clearTimeout(t)
        }
    }, [deps, fn, waitTime])
}


const ImageCropper = (props) => {
    const previewCanvasRef = useRef(null);
    const completedCrop = props.completedCrop;
    const setCompletedCrop = props.setCompletedCrop;
    const imgRef = useRef(null);
    const crop = props.crop;
    const setCrop = props.setCrop;
    const setScale = props.setScale;

    useDebounceEffect(
        async () => {
            if (
                typeof completedCrop !== "undefined" &&
                completedCrop.width &&
                completedCrop.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }
        },
        100,
        completedCrop,
    )

    function onImageLoad(e) {
        const { width, height, naturalWidth, naturalHeight } = e.currentTarget
        setScale({
            scaleX: naturalWidth / width,
            scaleY: naturalHeight / height
        });

        const crop = centerCrop(
            makeAspectCrop(
                {
                    // You don't need to pass a complete crop into
                    // makeAspectCrop or centerCrop.
                    unit: '%',
                    width: 100,
                },
                1,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
    }

    const onCropChange = (crop, percentCrop) => setCrop(percentCrop)

    return (
        <>
            <ReactCrop crop={crop} onCropChange={onCropChange} onChange={c => setCrop(c)} onComplete={c => setCompletedCrop(c)} aspect={1}>
                <img alt="" src={props.src} ref={imgRef} onLoad={onImageLoad} />
            </ReactCrop>
            {Boolean(completedCrop) && (
                <FormControl fullWidth>
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                        }}
                    />
                </FormControl>
            )}
        </>
    )
}

export default ImageCropper;