import * as React from 'react';
import { useContext } from "react";
import { Link, NavLink } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Md5 } from 'ts-md5/dist/md5';

import classes from './Navigation.module.css';
import AuthContext from "../../store/auth-context";
import UserContext from "../../store/user-context";

const Navigation = () => {
    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const permissions = userCtx.permissions;
    const alerts = userCtx.alerts;
    const encodedEmail = userCtx.userEmail !== null ? Md5.hashStr(userCtx.userEmail) : null;

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logoutHandler = () => {
        userCtx.setRole(null);
        authCtx.logout();
        handleCloseUserMenu();
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters className={classes.header}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <Link className={classes.linkText} to="/">Algodrops</Link>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem key="home" onClick={handleCloseNavMenu}>
                                <NavLink className={classes.linkText} to="" >Home</NavLink>
                            </MenuItem>
                            <MenuItem key="calendar" onClick={handleCloseNavMenu}>
                                <NavLink className={classes.linkText} to="calendar" >Calendar</NavLink>
                            </MenuItem>
                            <MenuItem key="recent-nft-sales" onClick={handleCloseNavMenu}>
                                <NavLink className={classes.linkText} to="recent-nft-sales" >Recent Sales</NavLink>
                            </MenuItem>
                            <MenuItem key="request" onClick={handleCloseNavMenu}>
                                <NavLink className={classes.linkText} to="request" >Request</NavLink>
                            </MenuItem>
                            {isLoggedIn && (<MenuItem key="contact-us" onClick={handleCloseNavMenu}>
                                <NavLink className={classes.linkText} to="contact-us" >Contact Us</NavLink>
                            </MenuItem>)}
                            <MenuItem key="bots" onClick={handleCloseNavMenu}>
                                <NavLink className={classes.linkText} to="bots" >Bots</NavLink>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <Link className={classes.linkText} to="/">ALGODROPS</Link>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <NavLink to="" >
                            <Button
                                className={classes.button}
                                key="home"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Home
                            </Button>
                        </NavLink>
                        <NavLink to="calendar" >
                            <Button
                                className={classes.button}
                                key="calendar"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                calendar
                            </Button>
                        </NavLink>
                        <NavLink to="recent-nft-sales" >
                            <Button
                                className={classes.button}
                                key="recent-nft-sales"
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Recent Sales
                            </Button>
                        </NavLink>
                        <NavLink to="request" >
                            <Button
                                className={classes.button}
                                key="request"
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Request
                            </Button>
                        </NavLink>
                        {isLoggedIn && (<NavLink to="contact-us" >
                            <Button
                                className={classes.button}
                                key="contact-us"
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Contact Us
                            </Button>
                        </NavLink>)}
                        <NavLink to="bots" >
                            <Button
                                className={classes.button}
                                key="bots"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Bots
                            </Button>
                        </NavLink>
                    </Box>

                    {isLoggedIn && (<Box sx={{ flexGrow: .04 }}>
                        <NavLink to="alerts" >
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={alerts.length} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </NavLink>
                    </Box>)}
                    {isLoggedIn && (<Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar src={`https://www.gravatar.com/avatar/${encodedEmail}?d=mp`} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {isLoggedIn && (<MenuItem onClick={handleCloseUserMenu} key="settings">
                                <NavLink style={{ textDecoration: "none", color: "black" }} to="settings"><Typography textAlign="center">Settings</Typography></NavLink>
                            </MenuItem>)}
                            {isLoggedIn && permissions.length > 0 && (<MenuItem onClick={handleCloseUserMenu} key="billing">
                                <NavLink style={{ textDecoration: "none", color: "black" }} to="billing"><Typography textAlign="center">Billing</Typography></NavLink>
                            </MenuItem>)}
                            <MenuItem key="logout" onClick={logoutHandler}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>)}
                    {!isLoggedIn && (<Box sx={{ flexGrow: 0 }}>
                        <NavLink to="login" >
                            <Button
                                className={classes.button}
                                key="login"
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Login
                            </Button>
                        </NavLink>
                    </Box>)}
                    {!isLoggedIn && (<Box sx={{ flexGrow: 0 }}>
                        <NavLink to="signup" >
                            <Button
                                key="signup"
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Sign Up
                            </Button>
                        </NavLink>
                    </Box>)}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navigation;