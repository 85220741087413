import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardMedia, Chip, Container, Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/fontawesome-free-brands';
import CancelIcon from '@mui/icons-material/Cancel';

import UserContext from "../../store/user-context";
import AuthContext from "../../store/auth-context";
import useHttp from "../../hooks/use-http";
import classes from './ViewCalendarItem.module.css';
import config from "../../config.json";

const ViewCalendarItem = () => {
    const navigate = useNavigate();
    const { error, sendRequest: fetchDrop } = useHttp();
    const { sendRequest: alertRequest } = useHttp();
    const params = useParams();
    const { dropId } = params;
    const [dropData, setDropData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [activeAlert, setActiveAlert] = useState(null);
    const userCtx = useContext(UserContext);
    const authCtx = useContext(AuthContext);
    const alerts = userCtx.alerts;
    const setAlerts = userCtx.setAlerts;
    const token = authCtx.token;
    const isLoggedIn = authCtx.isLoggedIn;

    useEffect(() => {
        const checkAlert = (drop) => {
            const activeAlert = alerts.filter((alert) => {
                if (alert.scheduleId === drop.schedule_id) {
                    return true;
                }

                return false;
            });

            setActiveAlert(activeAlert);
        }
        const handleDropResponse = (drop) => {
            console.log(error);
            checkAlert(drop);
            setLoaded(true);
            setDropData(drop);
        };

        if (loaded) {
            checkAlert(dropData);
            return;
        }

        fetchDrop(
            {
                url: `${config.URL}api/drops/${dropId}`
            },
            handleDropResponse
        );
    }, [fetchDrop, dropId, alerts, loaded, dropData]);

    useEffect(() => {
        if (error !== null) {
            navigate('/');
        }
    }, [error]);

    const addAlertHandler = (scheduleId) => {
        const addAlertResponseHandler = (response) => {
            const newAlerts = alerts.concat(response);
            setAlerts(newAlerts);
        }

        alertRequest(
            {
                url: config.URL + "api/email-alerts",
                method: "POST",
                body: {
                    schedule_id: scheduleId,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            },
            addAlertResponseHandler
        );
    }

    // @todo: duplicated code with AlertList.js
    const removeAlertHandler = (scheduleId) => {
        const removeAlertResponseHandler = (response) => {
            const updatedItems = alerts.filter(alert => alert.scheduleId !== scheduleId);
            setAlerts(updatedItems);
        }

        alertRequest(
            {
                url: config.URL + "api/email-alerts/" + scheduleId,
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            },
            removeAlertResponseHandler
        );
    }

    return (
        <>
            {dropData !== null && <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                <Paper
                    sx={{
                        p: 2,
                        margin: 'auto',
                        padding: 5,
                        maxWidth: '90%',
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CardActionArea component="a">
                                <CardMedia
                                    component="img"
                                    image={`images/drops/${dropData.image}`}
                                    alt="Drop Image"
                                />
                            </CardActionArea>
                        </Grid>
                        <Grid className={classes.gridItem} item xs={12} md={6}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1" component="div">
                                    <h2>{dropData.name}</h2>
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" component="div">
                                    <h3>{moment.unix(dropData.timestamp).format('YYYY-MM-DD h:mm a')}</h3>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <span>{dropData.drop_count} NFTs per drop</span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <span>{dropData.mint_price} Algo mint price</span>
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ paddingTop: "50px" }}>
                                    <Button variant='outlined' style={{ margin: 5 }}>
                                        <Link target="_blank" style={{ textDecoration: 'none' }} href={dropData.url}>
                                            Drop Link
                                        </Link>
                                    </Button>
                                    <Button variant='outlined' style={{ margin: 5 }}>
                                        <Link target="_blank" style={{ textDecoration: 'none' }} href={dropData.discord_link}>
                                            <FontAwesomeIcon icon={faDiscord} />
                                        </Link>
                                    </Button>
                                    <Button variant='outlined'>
                                        <Link target="_blank" style={{ textDecoration: 'none' }} href={dropData.twitter_link}>
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </Link>
                                    </Button>
                                </Typography>
                                {!isLoggedIn && (<Chip label="Login or Signup to get alerts on drop day" variant="outlined" />)}
                                {isLoggedIn && activeAlert !== null && activeAlert.length === 0 && !moment(moment.unix(dropData.timestamp).format('YYYY-MM-DD h:mm a')).isBefore(moment(new Date())) && (<Typography variant="body2" gutterBottom >
                                    <Button onClick={addAlertHandler.bind(null, dropData.schedule_id)} variant='outlined'>
                                        Get Email Alert<FavoriteIcon />
                                    </Button>
                                </Typography>)}
                                {isLoggedIn && activeAlert !== null && activeAlert.length > 0 && (<Typography variant="body2" gutterBottom >
                                    <Button onClick={removeAlertHandler.bind(null, dropData.schedule_id)} variant='outlined'>
                                        Remove Email Alert<CancelIcon />
                                    </Button>
                                </Typography>)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Divider variant="middle" />
                    <Container maxWidth="lg" style={{ textAlign: "center" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" color="text.secondary" style={{ margin: "15px" }}>
                                    <span>{dropData.description}</span>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ margin: "15px" }}>
                                    <span>
                                        Creator Address:
                                        <a rel="noreferrer" target="_blank" style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.6)" }} href={`https://algoexplorer.io/address/${dropData.creator_address}`}>
                                            {dropData.creator_address}
                                        </a>
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Container>}
        </>)
}

export default ViewCalendarItem;