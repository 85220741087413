import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardMedia, Chip, Container, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';

import UserContext from "../../store/user-context";
import AuthContext from "../../store/auth-context";
import useHttp from "../../hooks/use-http";
import config from "../../config.json";
import AndPayEmbedCheckout from '../andPay/AndPayEmbedCheckout';

const ProductView = () => {
    const navigate = useNavigate();
    const { error, sendRequest: fetchBillingPlan } = useHttp();
    const { sendRequest: fetchCheckoutLink } = useHttp();
    const params = useParams();
    const { billingPlanId } = params;
    const [billingPlan, setBillingPlan] = useState(null);
    const userCtx = useContext(UserContext);
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const isLoggedIn = authCtx.isLoggedIn;
    const permissions = userCtx.permissions;
    const [showCheckout, setShowCheckout] = useState(false);
    const [paymentLink, setPaymentLink] = useState(null);
    const [months, setMonths] = useState(1);
    const [monthError, setMonthError] = useState(null);

    useEffect(() => {
        if (billingPlanId !== null) {
            const handleBillingPlanResponse = (billingPlan) => {
                setBillingPlan(billingPlan);
            };

            fetchBillingPlan(
                {
                    url: `${config.URL}api/billing-plan/${billingPlanId}`
                },
                handleBillingPlanResponse
            );
        }
    }, [fetchBillingPlan, billingPlanId]);

    useEffect(() => {
        if (error !== null) {
            navigate('/');
        }
    }, [error]);

    const buyButtonHandler = () => {
        if (months === 0 || months === null || months === "" || isNaN(months)) {
            setMonthError("Enter valid number of months for subscription");
            return;
        }
        if (paymentLink !== null) {
            setShowCheckout(true);
            return;
        }

        const handleCheckoutLinkResponse = (checkoutData) => {
            setPaymentLink(checkoutData.payment_link);
            setShowCheckout(true);
        };

        fetchCheckoutLink(
            {
                url: `${config.URL}api/billing-charge`,
                method: "POST",
                body: {
                    month_quantity: months,
                    billing_plan_id: billingPlanId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            },
            handleCheckoutLinkResponse
        );
    };

    const monthChangeHandler = (event) => {
        if (parseInt(event.target.value) > 0) {
            setMonthError(null);
        }
        setMonths(event.target.value);
    }

    return (
        <>
            {billingPlan !== null && showCheckout === false && <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                <Paper
                    sx={{
                        p: 2,
                        margin: 'auto',
                        padding: 5,
                        maxWidth: '90%',
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CardActionArea component="a">
                                <CardMedia
                                    component="img"
                                    image={`images/${billingPlan.image}`}
                                    alt="Drop Image"
                                />
                            </CardActionArea>
                        </Grid>
                        <Grid style={{ textAlign: "center" }} item xs={12} md={6}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1" component="div">
                                    <h2>{billingPlan.name}</h2>
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" component="div">
                                    <h3>{billingPlan.amount} Algo Per Month</h3>
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" component="div">
                                    <TextField
                                        size="small"
                                        id="month_quantity"
                                        name="month_quantity"
                                        label="Number Of Months"
                                        type="number"
                                        value={months}
                                        onChange={monthChangeHandler}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {monthError !== null && <p style={{ color: "red", textAlign: "center", fontSize: 12 }}>{monthError}</p>}
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ paddingTop: "20px" }}>
                                    <span>Subscription for {months} Months</span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <span><b>Total</b> - {billingPlan.amount * months} Algo</span>
                                </Typography>
                                {isLoggedIn && !permissions.includes(billingPlan.plan_name) && (<Typography variant="body2" gutterBottom style={{ paddingTop: "20px" }}>
                                    <Button variant='outlined' style={{ margin: 5 }} onClick={buyButtonHandler}>
                                        Buy Now
                                    </Button>
                                </Typography>)}
                                {!isLoggedIn && (
                                    <div style={{ paddingTop: "20px" }}>
                                        <Chip label="Login or Signup to buy" variant="outlined" />
                                    </div>
                                )}
                                {isLoggedIn && permissions.includes(billingPlan.plan_name) && (
                                    <div style={{ paddingTop: "20px" }}>
                                        <Chip label="Already purchased subscription" variant="outlined" />
                                    </div>
                                )}
                                {isLoggedIn && permissions.includes(billingPlan.plan_name) && (
                                    <Typography variant="body2" gutterBottom style={{ paddingTop: "20px" }}>
                                        <NavLink style={{ textDecoration: "none", color: "black" }} to="/settings">
                                            <Button variant='outlined' style={{ margin: 5 }} >
                                                Bot Settings
                                            </Button>
                                        </NavLink>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Divider variant="middle" />
                    <Container maxWidth="lg" style={{ textAlign: "center" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" color="text.secondary" style={{ margin: "15px" }}>
                                    <span>{billingPlan.description}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Container>}
            {billingPlan !== null && paymentLink !== null && showCheckout === true && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AndPayEmbedCheckout payment_link={paymentLink} permission={billingPlan.plan_name} setShowCheckout={setShowCheckout} />
                </div>
            )}
        </>)
}

export default ProductView;