import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import App from './App';
import { AlertPopupContextProvider } from './store/alert-popup-context';
import { AuthContextProvider } from "./store/auth-context";
import { UserContextProvider } from './store/user-context';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AlertPopupContextProvider>
    <AuthContextProvider>
        <UserContextProvider>
            <HashRouter>
                <LocalizationProvider dateAdapter={AdapterMoment} >
                    <App />
                </LocalizationProvider>
            </HashRouter>
        </UserContextProvider>
    </AuthContextProvider>
</AlertPopupContextProvider>);