import Footer from "../Navigation/Footer";
import Navigation from "../Navigation/Navigation";
import Search from "../search/Search";

const Layout = (props) => {
    return (<>
        <div style={{ minHeight: "calc(91vh - 5px)"}}>
            <Navigation />
            <Search />
            <main>{props.children}</main>
        </div>
        <Footer />
    </>)
}

export default Layout;